import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Paper,
  Skeleton,
  Stack,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import AppBarDashboard from "../../components/AppBar/AppBar.Dashboard";
import {
  HeadingPrimary,
  HeadingSecondary,
} from "../../components/Styles/Heading";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Theme } from "../../config/aws-exports";
import { GridWrapper, Wrapper } from "./Course.styles";
import { Box } from "@mui/system";
import CustomCircularProgress, {
  CircularProgressWithLabel,
} from "../../components/CustomCircularProgress/CustomCircularProgress";

import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { Container } from "../../components/Styles/Container";
import { Container as CustomContainer } from "@mui/material";
import ModuleLogo from "./modules.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import config from "../../config/aws-exports";
import { authData } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import { setCookie } from "react-use-cookie";
import Cookies from "js-cookie";
import useFetch from "../../Hooks/useFetch";
import { useQueryParams } from "../../Hooks/useQueryParams";
import API from "@aws-amplify/api";
import RenderImage from "./RenderImage/RenderImages";
import {
  ObjectPopUp,
  StyledButton,
  TransitionsModal,
  // RatingPopUp,
} from "./ModalPopUp";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import "./Course.css";
import CourseCard from "../../components/Card/CourseCard";
import { useHistory, useLocation } from "react-router";
import RegisterForm from "./RegistrationForm";
import moment from "moment";

import RatePopUp from "./RatePopUp";
import ResponsiveDialog from "../LiveSession/SpeakerDialog";
import CourseFeedback from "./CourseFeedback";
import { Islamic } from "@syncfusion/ej2-react-schedule";

const Course = () => {
  const userDetails = useSelector(authData);
  let query = useQueryParams();
  const queryId = query.get("id") == undefined ? query.get("sid") : query.get("id");
  const location = useLocation();
  console.log({ location });

  const [open, setOpen] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  console.log({ courseDetails });
  const [courseResume, setCourseResume] = useState({});
  const [curObject, setCurObject] = useState({});
  const [curNugget, setCurNugget] = useState({});
  const [remaining, setRemaining] = useState({});
  console.log({ remaining });
  const [curObjIndex, setCurObjIndex] = useState(0);
  const [curNuggetId, setCurNuggetId] = useState("");
  const [curNugIndex, setCurNugIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);
  const [tpProgress, setTpProgress] = useState("");
  console.log(tpProgress?.charAt(0));
  const [markComplete, setMarkComplete] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [perScore, setPerScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [quizResponse, setQuizResponse] = useState([]);
  //let coursedetails = useSelector(state => state.Course);
  const [isLoading, setIsLoading] = useState(true);
  const variants = ["h4", "h4", "h4", "h4"];

  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bmId, setBmId] = useState();
  const [isBookmarkedLoaded, setIsBookmarkedLoaded] = useState(false);
  const [showScore, setShowScore] = useState(false);
  const [btnClick, setBtnClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const coursedestailref = useRef({});
  const [rateOpen, setRateOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [speakerOpen, setSpeakerOpen] = useState(false);
  const [speakerDetails, setSpeakerDetails] = useState({});
  const [quizAnswers, setQuizAnswers] = useState([]);
  const history = useHistory();

  const handleClickOpen = () => {
    setRateOpen(true);
  };
  const handleClose = () => {
    setRateOpen(false);
  };
  const handleClickFeedbackOpen = () => {

    setFeedbackOpen(true);
  };
  const handleFeedbackClose = () => {
    setFeedbackOpen(false);
  };
  useEffect(() => {

    if (userDetails === 0) {
      var obj = {};
      obj.tid = queryId;
      obj.type = "course";
      localStorage.setItem("deeplink", JSON.stringify(obj));
      //history.push(`/`);
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      getTopicDetails();
    }
    window.scrollTo(0, 0);
    return () => {
      if (
        coursedestailref.current.userdataset !== undefined &&
        coursedestailref.current.userdataset.userdata === false
      ) {
        updateCourseAnalytics("bounce");
      }
    };
  }, [queryId]);
  useEffect(() => {
    const feedback = query.get("feedback");
    const sid = query.get("sid");
    const userId = query.get("userId");
    if (sid && userId && feedback) {
      const submitFeedback = async () => {
        try {
          API.post(
            config.aws_cloud_logic_custom_name,
            `/submitCourseFeedback?user_id=${userId}&tid=${sid}&feedback=${feedback}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      };

      submitFeedback();
    }

    return () => { };
  }, []);
  async function getTopicDetails() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails?.username,
        tenant: userDetails?.locale,
        topicid: queryId,
        urid: userDetails?.uData?.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getTopicDetails",
        bodyParam
      );

      coursedestailref.current.userdataset = response.userdataset;
      coursedestailref.current.tid = response.tid;
      setCourseDetails(response);

      if (
        response.userdataset !== undefined &&
        response.userdataset.userprogress !== undefined &&
        response.userdataset.userprogress.bookmark === true
      ) {
        setIsBookmarked(true);
      } else {
        setIsBookmarked(false);
      }
      setCourseResume(response.nresume);
      setRemaining({
        mremaining: response.mremaining,
        tremaining: response.tremaining,
      });
      if (response.userdataset?.userdata === true) {
        setTotalProgress(
          response.userdataset.cobj === undefined
            ? 0
            : response.userdataset.cobj
        );
        setTpProgress(response.userdataset.tp);
      }
      updateRecentViewed(response);
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", response.Policy);
      Cookies.set("CloudFront-Signature", response.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);
      // dispatch(rcoursedetails(response));
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }
  console.log({ isLoading });

  async function addAndRemoveBookmark(val) {
    try {
      // const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      //setLoading(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tid: courseDetails.tid,
          type: "topics",
          eid: userDetails.username,
          userfullname: userDetails.name,
          emailid: userDetails.email,
          tenant: userDetails.locale,
          ur_id: userDetails.uData.ur_id,
          bookmark: val === 1 ? true : false,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      if (val === 1) {
        bodyParam.body.bookmark_date = 1;
      }
      // let apiname = val === 1 ? "/addBookMark" : "/deleteBookmark";

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );

      val === 1 ? setIsBookmarked(true) : setIsBookmarked(false);
      setIsBookmarkedLoaded(false);
    } catch (error) {
      console.error(error);
    }
  }

  const getBookmarkStatus = async (tid) => {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bitem_id: tid,
        eid: userDetails.username,
        emailid: userDetails.email,
        tenant: userDetails.locale,
        atype: 2,
        urid: userDetails.uData.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );

      // isBookmarked = JSON.stringify(result.data).toString();
      if (
        response === undefined ||
        response.items === undefined ||
        response.items[0] === undefined ||
        response.items[0].b_id === undefined ||
        response.items[0].b_id === null
      ) {
        setIsBookmarked(false);
      } else {
        setBmId(response.items[0].b_id);
        setIsBookmarked(true);
      }
      setIsBookmarkedLoaded(false);
    } catch (err) {
      console.error(err);
    }
  };

  async function getQuizScore(objects) {
    try {
      //const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      setIsLoading(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tenant: userDetails.locale,
          eid: userDetails.username,
          emailid: userDetails.email,
          obj_id: objects.oid,
          ur_id: userDetails?.uData?.ur_id,
          tid: courseDetails.tid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getQuizScore",
        bodyParam
      );
      console.log(response?.result);
      setQuizAnswers(response?.result);
      setShowScore(true);
      setIsLoading(false);
      setBtnClick(true);
      setPerScore(response.score);
      setTotalScore(response.score == -1 ? null : response.score);
    } catch (error) {
      console.error(error);
    }
  }
  async function updateRecentViewed(cDetails) {
    try {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tenant: userDetails.locale,
          eid: userDetails.username,
          refid: cDetails.tid,
          title: cDetails.ttitle,
          reftype: 1,
          ur_id: userDetails?.uData?.ur_id,
          pdate: new Date().getTime(),
          tduration: cDetails.tduration,
          noofnuggets: cDetails.noofnuggets,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateRecentViewed",
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function updateCourseAnalytics(ctype) {
    const bodyParam1 = {
      body: {
        oid: config.aws_org_id,
        tid: courseDetails.tid,
        email: userDetails.username,
        tenant: userDetails.locale,
        ctype,
        ur_id: userDetails.uData.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (ctype === "bounce") {
      bodyParam1.body.bounce_cnt = 1;
    }
    if (ctype === "completed") {
      bodyParam1.body.comp_date = 1;
    }
    console.log("updateTopicReport==", bodyParam1.body);
    const response1 = await API.post(
      config.aws_cloud_logic_custom_name,
      "/updateTopicReport",
      bodyParam1
    );
  }

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
    history.push("/dashboard");
  }
  // const breadcrumbs = [
  //   <Link
  //     underline="hover"
  //     key="1"
  //     color="inherit"
  //     href="/dashboard"
  //     onClick={handleClick}
  //   >
  //     Dashboard
  //   </Link>,
  //   <Typography key="3" color={Theme.color.text}>
  //     {courseDetails && courseDetails.ttitle}
  //   </Typography>,
  // ];

  const breadcrumbs = [
    <Link to={location.hash === "#bookmark" ? "/bookmark" : "/dashboard"}>
      {location.hash !== "#bookmark" ? "Dashboard" : "Bookmark"}
    </Link>,
    <Typography key="3" color={Theme.color.text}>
      {courseDetails && courseDetails?.ttitle?.length > 150
        ? courseDetails?.ttitle.substring(0, 150) + "..."
        : courseDetails?.ttitle}
    </Typography>,
  ];

  const handleRegisterOpen = () => {
    setRegisterOpen(true);
  };
  const handleRegisterClose = () => {
    setRegisterOpen(false);
  };
  // if (error) return error;
  return (
    <>
      {courseDetails && courseDetails?.tstatus !== false ? (
        <Wrapper spacing={2} sx={{ m: { xs: "1rem", sm: "0 3rem" } }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ color: Theme.color.black }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 1, md: 3 }}
              divider={
                <Divider
                  sx={{ borderColor: "#333" }}
                  orientation="vertical"
                  flexItem
                />
              }
            >
              <HeadingPrimary
                style={{ maxWidth: "600px", textAlign: "justify" }}
              >
                {courseDetails?.ttitle}
              </HeadingPrimary>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <StarRoundedIcon sx={{ color: "yellow" }} />
                  {courseDetails ? (
                    <span>{Math.round(courseDetails?.star ?? 0)}</span>
                  ) : (
                    <span>4.5</span>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <img src={ModuleLogo} alt="" height="30" />
                  {courseDetails ? (
                    <span>{courseDetails.noofnuggets} Modules</span>
                  ) : (
                    <span>3 Modules</span>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <AccessTimeIcon
                    style={{
                      fill: "#777",
                      fontSize: "1.4rem",
                    }}
                  />
                  {courseDetails && !isLoading ? (
                    <span>
                      {Math.floor(courseDetails?.tduration / 3600)}h{" "}
                      {Math.floor((courseDetails?.tduration % 3600) / 60)}m{" "}
                      {/* {Math.floor((courseDetails?.tduration % 3600) % 60)}s */}
                    </span>
                  ) : (
                    <span>02h 30m</span>
                  )}
                </Stack>
              </Stack>
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 1, md: 3 }}
              alignItems="center"
            >
              {courseDetails?.userdataset?.userdata === true &&
                tpProgress !== undefined &&
                !open &&
                courseDetails?.userdataset?.userprogress !== undefined &&
                courseDetails?.userdataset?.userprogress?.feedback === false &&
                (tpProgress?.charAt(0) == 2 || tpProgress?.charAt(0) == 3) && (
                  <>

                    {/* <Button
                      variant="outlined"
                      sx={{
                        color: Theme.color.secondary,
                        borderColor: Theme.color.secondary,
                      }}
                      onClick={handleClickFeedbackOpen}
                    >
                      Feeback
                    </Button> */}
                    <a
                      variant="outlined"
                      style={{
                        color: Theme.color.secondary,
                        borderColor: Theme.color.secondary,
                        marginLeft: '200px',
                        border: '1px solid grey',
                        padding: '5px 10px',
                        textDecoration: 'none',
                        borderRadius: '4px'
                      }}
                      href={`https://usp.az1.qualtrics.com/jfe/form/SV_aeEj4dGn2w9OoJw?ProgramName=${courseDetails?.ttitle}&EmailAddress=${userDetails?.uData.emailid}
            &OrgName=${userDetails?.uData.oid}&sessID=${courseDetails?.tid}&userId=${userDetails?.uData.ur_id}&SessionType='onDemand'&Session_Date=${courseDetails?.con}&ProgramID=${courseDetails?.tid}`}
                      target="_blank"
                    >
                      Feedback
                    </a>
                  </>
                )}
              {courseDetails?.userdataset?.userdata === true &&
                tpProgress !== undefined &&
                !open &&
                courseDetails?.userdataset?.userprogress !== undefined &&
                courseDetails?.userdataset?.userprogress?.rating === null &&
                (tpProgress?.charAt(0) == 2 || tpProgress?.charAt(0) == 3) && (
                  <>

                    <Button
                      variant="outlined"
                      sx={{
                        color: Theme.color.secondary,
                        borderColor: Theme.color.secondary,
                      }}
                      onClick={handleClickOpen}
                    >
                      Rate Course
                    </Button>

                  </>
                )}

              {isBookmarked ? (
                <div
                  style={{ cursor: "pointer", marginLeft: "1.5rem" }}
                  onClick={() => {
                    setIsBookmarkedLoaded(true);
                    addAndRemoveBookmark(0);
                  }}
                >
                  <BookmarkRoundedIcon
                    sx={{
                      fill: "yellow",
                      stroke: "yellow",
                      strokeWidth: "1px",
                      strokeLinejoin: "round",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{ cursor: "pointer", marginLeft: "1.5rem" }}
                  onClick={() => {
                    setIsBookmarkedLoaded(true);
                    addAndRemoveBookmark(1);
                  }}
                >
                  <BookmarkRoundedIcon
                    style={{
                      fill: "none",
                      stroke: "#646464",
                      strokeWidth: "1px",
                      strokeLinejoin: "round",
                    }}
                  />
                </div>
              )}
              {rateOpen && (
                <RatePopUp
                  courseDetails={courseDetails}
                  getTopicDetails={getTopicDetails}
                  open={rateOpen}
                  setOpen={setRateOpen}
                  close={handleClose}
                />
              )}
              {feedbackOpen && (
                <Dialog
                  open={feedbackOpen}
                  aria-labelledby="responsive-dialog-title"
                  BackdropProps={{
                    sx: {
                      backgroundColor: Theme.backDropOpacity,
                    },
                  }}
                  scroll="paper"
                  maxWidth="lg"
                >
                  <CourseFeedback
                    handleFeedbackClose={handleFeedbackClose}
                    sid={courseDetails?.tid}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    type="course"
                    ur_id={userDetails?.uData.ur_id}
                    handleClose={handleClose}
                    refetch={getTopicDetails}
                  />
                </Dialog>
              )}
            </Stack>
            <Backdrop
              style={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                color: "#fff",
              }}
              open={isBookmarkedLoaded}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Stack>
          <GridWrapper layout={courseDetails.prerequisites === true && "2/10"}>
            <Paper
              sx={{ padding: 3, boxShadow: Theme.boxShadow }}
              className="item-1"
            >
              <HeadingSecondary>Course description</HeadingSecondary>
              <div className="overview">
                <div className="overview-left">
                  {courseDetails &&
                    courseDetails?.tdescription
                      ?.split("\n")
                      ?.map((desc) => <p>{desc}</p>)}
                  <HeadingSecondary>
                    Upon completion of the course, you will be able to
                  </HeadingSecondary>
                  {courseDetails &&
                    courseDetails?.learning_objectives
                      ?.split("\n")
                      ?.map((learn) => <p>{learn}</p>)}
                  <HeadingSecondary>Who Should participate</HeadingSecondary>
                  <p>
                    {courseDetails &&
                      courseDetails?.target_audience
                        ?.split("\n")
                        ?.map((tar) => <p>{tar}</p>)}
                  </p>
                </div>
                <div className="overview-right">
                  <table>
                    <tbody>


                      {courseDetails?.priceinr &&
                        courseDetails?.priceusd &&
                        courseDetails?.coursetype === "paid" && (
                          <tr>
                            <td className="head"> Fees &#58;</td>
                            <td>
                              <p>
                                INR {courseDetails?.priceinr ?? ""} (inc of GST){" "}
                                <span>
                                  /{" "}
                                  <Box component="span" sx={{ mx: 0.3 }}>
                                    {" "}
                                    USD{" "}
                                  </Box>
                                  {courseDetails?.priceusd ?? ""}
                                </span>
                              </p>
                            </td>
                          </tr>
                        )}

                      {courseDetails?.earlypriceinr &&
                        courseDetails?.earlypriceusd &&
                        courseDetails?.earlydate &&
                        courseDetails?.coursetype === "paid" && (
                          <tr>
                            <td className="head">Early Bird Offer &#58;</td>
                            <td>
                              <p>
                                INR {courseDetails?.earlypriceinr ?? ""} (inc of
                                GST) /{" "}
                                <Box component="span" sx={{ mx: 0.3 }}>
                                  {" "}
                                  USD{" "}
                                </Box>
                                {courseDetails?.earlypriceusd ?? ""} till{" "}
                                {moment(courseDetails?.earlydate).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                            </td>
                          </tr>
                        )}
                      {courseDetails?.perdis !== null &&
                        courseDetails?.perdis !== 0 &&
                        courseDetails?.coursetype === "paid" && (
                          <tr>
                            <td className="head" style={{ minWidth: "160px" }}>
                              Percentage Discount &#58;
                            </td>
                            <td>
                              <p>{courseDetails?.perdis} %</p>
                            </td>
                          </tr>
                        )}
                      {courseDetails?.priceafterdisinr !== null &&
                        courseDetails?.priceafterdisusd !== null &&
                        courseDetails?.coursetype === "paid" && (
                          <tr>
                            <td className="head">Price after Discount &#58;</td>
                            <td>
                              <p>
                                INR {+courseDetails?.priceafterdisinr} (inc of
                                GST) /{" "}
                                <Box component="span" sx={{ mx: 0.3 }}>
                                  {" "}
                                  USD{" "}
                                </Box>
                                {(+courseDetails?.priceafterdisusd).toFixed(
                                  2
                                )}{" "}
                              </p>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                <div>
                  {(courseDetails?.coursetype === "paid" ||
                    courseDetails?.coursetype === "free") && (
                      <div>
                        <p className="headp">USP approved instructor:</p>
                        <div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap', height: '250px', overflow: 'auto' }}>
                          {courseDetails?.sp?.map((item) => (

                            <div style={{ textAlign: 'center', width: '200px' }}>
                              <img
                                src={`https:///${config.aws_cloudfront_url
                                  }/${config.aws_org_id.toLowerCase()}-resources/images/speaker-images/${item?.timgname
                                  }`}
                                height="200"
                                width="200"
                                alt={item?.name}
                                onClick={() => {
                                  setSpeakerOpen(true);
                                  setSpeakerDetails(item);
                                }}
                              />
                              <p style={{ fontWeight: 'bold' }}>{item?.name}</p>
                              <div style={{ overflow: 'hidden', display: 'inline' }} title={item?.designation}>{item?.designation}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {registerOpen && (
                <Dialog
                  open={registerOpen}
                  aria-labelledby="responsive-dialog-title"
                  BackdropProps={{
                    sx: {
                      backgroundColor: Theme.backDropOpacity,
                    },
                  }}
                  scroll="paper"
                  maxWidth="lg"
                >
                  <RegisterForm
                    handleClose={handleRegisterClose}
                    sessionData={courseDetails}
                    setRegButton={setRegisterOpen}
                    // setSessionJoin={setSessionJoin}
                    refetch={getTopicDetails}
                    regData={courseDetails.regidtls ?? {}}
                  />
                </Dialog>
              )}
              {speakerOpen && (
                <ResponsiveDialog
                  open={speakerOpen}
                  setOpen={setSpeakerOpen}
                  speakerDetails={speakerDetails}
                />
              )}
              <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                {(courseDetails?.userdataset?.userprogress?.aprstatus ===
                  null ||
                  courseDetails?.userdataset?.userprogress?.aprstatus !== 1) &&
                  (courseDetails?.userdataset?.userprogress?.pstatus ===
                    undefined ||
                    courseDetails?.userdataset?.userprogress?.pstatus === null ||
                    courseDetails?.userdataset?.userprogress?.pstatus !== 2) &&
                  (courseDetails?.userdataset?.userprogress?.pstatus ===
                    undefined ||
                    courseDetails?.userdataset?.userprogress?.pstatus === null ||
                    courseDetails?.userdataset?.userprogress?.pstatus !== 0) &&
                  (courseDetails?.userdataset?.userprogress?.pstatus ===
                    undefined ||
                    courseDetails?.userdataset?.userprogress?.pstatus === null ||
                    courseDetails?.userdataset?.userprogress?.pstatus !== 3) &&
                  (courseDetails?.userdataset?.userprogress?.pstatus ===
                    undefined ||
                    courseDetails?.userdataset?.userprogress?.pstatus === null ||
                    courseDetails?.userdataset?.userprogress?.pstatus !== 11) ? (
                  <>
                    <Button
                      onClick={handleRegisterOpen}
                      variant="contained"
                      sx={{
                        bgcolor: Theme.color.secondary,
                        textTransform: "unset",
                      }}
                    >
                      Register
                    </Button>
                  </>
                ) : (
                  <>
                    {courseDetails && courseDetails?.userdataset?.userdata ? (
                      <ObjectPopUp
                        open={open}
                        setOpen={setOpen}
                        setCourseDetails={setCourseDetails}
                        courseDetails={courseDetails}
                        setCurObject={setCurObject}
                        curObject={curObject}
                        setCurNugget={setCurNugget}
                        curNugget={curNugget}
                        setCurObjIndex={setCurObjIndex}
                        curObjIndex={curObjIndex}
                        setCurNuggetId={setCurNuggetId}
                        curNuggetId={curNuggetId}
                        progress={progress}
                        setProgress={setProgress}
                        curNugIndex={curNugIndex}
                        setCurNugIndex={setCurNugIndex}
                        markComplete={markComplete}
                        setMarkComplete={setMarkComplete}
                        setStartTime={setStartTime}
                        startTime={startTime}
                        courseResume={courseResume}
                        setcourseResume={setCourseResume}
                        remaining={remaining}
                        setRemaining={setRemaining}
                        setTotalProgress={setTotalProgress}
                        totalProgress={totalProgress}
                        tpProgress={tpProgress}
                        setTpProgress={setTpProgress}
                        perScore={perScore}
                        setPerScore={setPerScore}
                        totalScore={totalScore}
                        setTotalScore={setTotalScore}
                        showScore={showScore}
                        setShowScore={setShowScore}
                        btnClick={btnClick}
                        setBtnClick={setBtnClick}
                        loading={isLoading}
                        setLoading={setIsLoading}
                        certificate={certificate}
                        setCertificate={setCertificate}
                        updateCourseAnalytics={updateCourseAnalytics}
                        setQuizResponse={setQuizResponse}
                        quizResponse={quizResponse}
                        setQuizAnswers={setQuizAnswers}
                        quizAnswers={quizAnswers}
                        getTopicDetails={getTopicDetails}
                      />
                    ) : (
                      <TransitionsModal
                        open={open}
                        setOpen={setOpen}
                        setCourseDetails={setCourseDetails}
                        setCurNugget={setCurNugget}
                        setCurObject={setCurObject}
                        setCurObjIndex={setCurObjIndex}
                        setCurNuggetId={setCurNuggetId}
                        setCurNugIndex={setCurNugIndex}
                        setCertificate={setCertificate}
                        setProgress={setProgress}
                        setMarkComplete={setMarkComplete}
                        setStartTime={setStartTime}
                        courseDetails={courseDetails}
                        setTpProgress={setTpProgress}
                        setcourseResume={setCourseResume}
                        updateCourseAnalytics={updateCourseAnalytics}
                        setQuizResponse={setQuizResponse}
                        quizResponse={quizResponse}
                        setQuizAnswers={setQuizAnswers}
                        quizAnswers={quizAnswers}
                      />
                    )}
                  </>
                )}
              </Stack>
            </Paper>

            <Paper className="item-2" sx={{ boxShadow: Theme.boxShadow }}>
              <Stack direction="column" sx={{ mt: 3, mx: 3 }}>
                <HeadingSecondary>Your Progress</HeadingSecondary>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ height: 200 }}
                >
                  {courseDetails ? (
                    <CustomCircularProgress
                      courseDetails={courseDetails}
                      totalProgress={totalProgress}
                    />
                  ) : (
                    <CircularProgressWithLabel />
                  )}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                sx={{ borderTop: "1px solid #000", height: 140 }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  sx={{
                    flexGrow: 1,
                    borderRight: "1px solid #000",
                    height: 140,
                  }}
                >
                  <img src={ModuleLogo} alt="" height="30" width="30" />
                  <HeadingSecondary>Remaining Modules</HeadingSecondary>
                  <Box>{remaining.mremaining} Modules</Box>
                </Stack>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <AccessTimeIcon
                    style={{
                      fill: "#777",
                      fontSize: "1.4rem",
                    }}
                  />
                  <HeadingSecondary>Time Remaining</HeadingSecondary>
                  {isLoading ? (
                    <span>0h 0m</span>
                  ) : (
                    <Box>
                      {Math.floor(remaining?.tremaining / 3600) <= 0
                        ? 0
                        : Math.floor(remaining?.tremaining / 3600)}
                      h{" "}
                      {Math.floor((remaining?.tremaining % 3600) / 60) <= 0
                        ? 0
                        : Math.floor((remaining?.tremaining % 3600) / 60)}
                      m{" "}
                      {/* {Math.floor((remaining?.tremaining % 3600) % 60) <= 0
                        ? 0
                        : Math.floor((remaining?.tremaining % 3600) % 60)}
                      s */}
                    </Box>
                  )}
                  {/* {isLoading ? (
                    <span>0h 0m 0s</span>
                  ) : (
                    <span>
                    {Math.floor(courseDetails?.tduration / 3600)}h{" "}
                    {Math.floor((courseDetails?.tduration % 3600) / 60)}m{" "}
                    {Math.floor((courseDetails?.tduration % 3600) % 60)}s
                  </span>
                  )} */}
                </Stack>
              </Stack>
            </Paper>
            <Paper sx={{ boxShadow: Theme.boxShadow }} className="item-3">
              {/* <HeadingSecondary style={{ margin: "24px" }}>
                Modules
              </HeadingSecondary> */}

              {courseDetails?.nuggets?.map((nugget, nidx) => (
                <Box key={nidx + "nmv"}>
                  <Stack
                    direction={{ sm: "row" }}
                    alignItems={{ sm: "center" }}
                    justifyContent={{ sm: "space-between" }}
                    spacing={1}
                    sx={{ mr: { xs: 0, sm: 1 } }}
                  >
                    <Box
                      key={nidx + "12ASDF"}
                      sx={{
                        background: `linear-gradient(to right, ${Theme.color.secondary} 55%, ${Theme.color.secondary} 100%)`,
                        width: "max-content",
                        maxWidth: 800,
                        pl: { xs: 1, lg: "5%" },
                        py: 1,
                        pr: 2,
                        borderRadius: "0 30px 30px 0",
                        color: "#fff",
                      }}
                    >
                      {`${nugget.ntitle}`}
                    </Box>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ mr: { xs: 0, sm: 1 } }}
                    >
                      <AccessTimeIcon style={{ fontSize: "21px" }} />
                      <span>
                        {Math.floor(nugget.nduration / 3600) === 0
                          ? ""
                          : Math.floor(nugget.nduration / 3600) + "h"}{" "}
                        {Math.floor((nugget.nduration % 3600) / 60)}m{" "}
                        {/* {Math.floor((nugget.nduration % 3600) % 60)}s */}
                      </span>
                    </Stack>
                  </Stack>
                  {nugget?.objects?.map((object, oidx) => (
                    <Stack
                      key={oidx + "JKS"}
                      sx={{ height: "50px", pl: { xs: 1, lg: "2%" } }}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Box>
                        <RenderImage type={object.otype} oStatus={object.op} />
                      </Box>
                      {object.op === 0 ? (
                        <Box>{object.otitle}</Box>
                      ) : (
                        <Box
                          className="object-text"
                          onClick={() => {
                            let obj = {};
                            obj.curNugget = nugget;
                            obj.curObject = object;
                            obj.robject = oidx;
                            setCurNugget(nugget);
                            setCurObject(object);
                            setCurObjIndex(oidx);
                            setCurNuggetId(nugget.nid);
                            setCurNugIndex(nidx);
                            setLoading(false);
                            if (
                              (object.qtype === 1 || object.qtype === "1") &&
                              (object.op === 2 || object.op === "2")
                            ) {
                              setLoading(true);
                              getQuizScore(object);
                            }

                            setProgress(Number(object.oduration) - 2);
                            setMarkComplete(false);

                            setStartTime(new Date().getTime());
                            setOpen(true);
                          }}
                          style={{
                            cursor:
                              ((courseDetails?.userdataset?.userprogress?.aprstatus !== 1 &&
                                courseDetails?.userdataset?.userprogress?.pstatus !== 11))
                                ? 'none'
                                : 'pointer', // Change cursor based on condition
                            color:
                            ((courseDetails?.userdataset?.userprogress?.aprstatus !== 1 &&
                              courseDetails?.userdataset?.userprogress?.pstatus !== 11))
                                ? 'grey'
                                : 'rgb(39, 87, 128)', // Set text color to grey based on condition
                            textDecoration:
                            ((courseDetails?.userdataset?.userprogress?.aprstatus !== 1 &&
                              courseDetails?.userdataset?.userprogress?.pstatus !== 11))
                                ? 'none'
                                : 'underline', // Remove underline based on condition
                          }}
                        >
                          {object.otitle}

                        </Box>
                      )}
                    </Stack>
                  ))}
                </Box>
              ))}
            </Paper>

            {courseDetails.prerequisites === true && (
              <Paper
                className="item-4"
                sx={{ padding: 3, boxShadow: Theme.boxShadow }}
              >
                <HeadingSecondary>Prerequisites</HeadingSecondary>

                <CourseCard
                  title={
                    courseDetails?.prerequisitescourses !== undefined
                      ? courseDetails?.prerequisitescourses[0]?.ttitle
                      : ""
                  }
                  tid={
                    courseDetails?.prerequisitescourses !== undefined
                      ? courseDetails.prerequisitescourses[0]?.tid
                      : ""
                  }
                />
              </Paper>
            )}
          </GridWrapper>
        </Wrapper>
      ) : (
        <div
          style={{
            height: "90vh",
            width: "100vw",
            display: "grid",
            placeItems: "center",
          }}
        >
          <div
            style={{
              width: "400px",
              height: "200px",
              background: "#1974b5",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: ".8rem",
            }}
          >
            <div style={{ margin: "2rem 0", textAlign: "center" }}>
              <p
                style={{
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                Content you are looking for could not be found
              </p>
              <button
                style={{
                  border: "none",
                  color: "#1974b5",
                  background: "#fff",
                  padding: ".5rem 2rem",
                  marginTop: "2rem",
                  borderRadius: ".4rem",
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/dashboard")}
              >
                Go to dashboard
              </button>
            </div>
            <div
              style={{
                marginBottom: "2rem",
                color: "#fff",
              }}
            >
              <span style={{ marginRight: ".5rem" }}> Please contact</span>
              <a href="https://www.usp.org/help">help@www.usp.org</a>
              <span style={{ marginLeft: ".5rem" }}> for assistance</span>
            </div>
          </div>
        </div>
      )}

      <Backdrop
        style={{
          color: "#fff",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 9999, // Ensure it's above other elements
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </>
  );
};

export default Course;
