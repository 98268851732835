import { AccessTime, StarRounded } from "@mui/icons-material";
import { Box, Divider, Paper, Stack } from "@mui/material";
import { API } from "aws-amplify";
import moment from "moment";
import React from "react";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  HeadingPrimary,
  HeadingSecondary,
} from "../../components/Styles/Heading";
import { Theme } from "../../config/aws-exports";
import { GridWrapper } from "../Course/Course.styles";
import { StyledButton } from "../Course/ModalPopUp";
import RenderImage from "../Course/RenderImage/RenderImages";
import { Wrapper } from "../LiveSession/LiveSession.Styled";
import ModuleLogo from "./modules.png";
import config from "../../config/aws-exports";
import AppBarLandingPage from "../../components/AppBar/AppBar.landingPage";
import { useQueryParams } from "../../Hooks/useQueryParams";
import SignInAndSignUp from "../../components/SignInAndSignUp/SignInAndSignUp";
import SpeakerDialog from "../../pages/LiveSession/SpeakerDialog";
const PreLoginCourse = () => {
  const [courseDetails, setCourseDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loginOpen, setLoginOpen] = useState(false);
  const onlySignUp = useRef(false);
  const query = useQueryParams();
  const id = query.get("id");

  const [openSpeaker, setSpeaker] = useState(false);

  const [speakerDetails, setSpeakerDetails] = useState({});
  useEffect(() => {
    async function getTopic() {
      const bodyParam = {
        body: {
          vtid: id,
          oid: config.aws_org_id,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/getTopic",
          bodyParam
        );
        const { rows } = response;
        setCourseDetails(rows[0]);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    }
    getTopic();
  }, [id]);

  const handleClickSpeakerOpen = async (details) => {
    setSpeaker(true);
    setSpeakerDetails(details);
  };

  const handleLoginClickOpen = (prop) => () => {
    if (prop === "login") {
      setLoginOpen(true);
      onlySignUp.current = false;
    } else {
      setLoginOpen(true);
      onlySignUp.current = true;
    }
  };
  return (
    <>
      <AppBarLandingPage handleLoginClick={handleLoginClickOpen} />
      <Stack margin="3rem">
        <Wrapper spacing={2} sx={{ m: { xs: "1rem", sm: "0 3rem" } }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 1, md: 3 }}
              divider={
                <Divider
                  sx={{ borderColor: "#333" }}
                  orientation="vertical"
                  flexItem
                />
              }
            >
              <HeadingPrimary>{courseDetails?.tn}</HeadingPrimary>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <StarRounded sx={{ color: "yellow" }} />
                  {courseDetails ? (
                    <span>{Math.round(courseDetails?.star ?? 0)}</span>
                  ) : (
                    <span>4.5</span>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <img src={ModuleLogo} alt="" height="30" />
                  {courseDetails ? (
                    <span>{courseDetails?.nugcnt} Modules</span>
                  ) : (
                    <span>3 Modules</span>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <AccessTime
                    style={{
                      fill: "#777",
                      fontSize: "1.4rem",
                    }}
                  />
                  {courseDetails && !isLoading ? (
                    <span>
                      {Math.floor(courseDetails?.dur / 3600)}h{" "}
                      {Math.floor((courseDetails?.dur % 3600) / 60)}m{" "}
                      {/* {Math.floor((courseDetails?.dur % 3600) % 60)}s */}
                    </span>
                  ) : (
                    <span>02h 30m</span>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="column" gap="3rem" margin="3rem 0 ">
            <Paper
              sx={{ padding: 3, boxShadow: Theme.boxShadow }}
              className="item-1"
            >
              <HeadingSecondary>Overview</HeadingSecondary>
              <p>{courseDetails && courseDetails.tdesc}</p>

              <table style={{ width: "100%", display: "flex" }}>
                <tbody style={{ marginLeft: "auto" }}>
                  {(courseDetails?.coursetype === "paid" ||
                    courseDetails?.coursetype === "free") && (
                    <tr>
                      <td>Speaker Name :</td>
                      <td>
                        {courseDetails?.sp?.map((item) => (
                          <p style={{ textTransform: "capitalize" }}>
                            {item?.name}
                          </p>
                        ))}
                      </td>
                    </tr>
                  )}
                  {courseDetails?.priceinr &&
                    courseDetails?.priceusd &&
                    courseDetails?.coursetype === "paid" && (
                      <tr>
                        <td>Fees &#58;</td>
                        <td>
                          <p>
                            INR {courseDetails?.priceinr ?? ""} + GST
                            <span>
                              /{" "}
                              <Box component="span" sx={{ mx: 0.3 }}>
                                {" "}
                                USD{" "}
                              </Box>
                              {courseDetails?.priceusd ?? ""}
                            </span>
                          </p>
                        </td>
                      </tr>
                    )}

                  {courseDetails?.earlypriceinr &&
                    courseDetails?.earlypriceusd &&
                    courseDetails?.earlydate &&
                    courseDetails?.coursetype === "paid" && (
                      <tr>
                        <td>Early Bird Offer &#58;</td>
                        <td>
                          <p>
                            INR {courseDetails?.earlypriceinr ?? ""} + GST /{" "}
                            <Box component="span" sx={{ mx: 0.3 }}>
                              {" "}
                              USD{" "}
                            </Box>
                            {courseDetails?.earlypriceusd ?? ""} till{" "}
                            {moment(courseDetails?.earlydate).format(
                              "DD MMM YYYY"
                            )}
                          </p>
                        </td>
                      </tr>
                    )}
                  {courseDetails?.perdis !== null &&
                    courseDetails?.perdis !== 0 &&
                    courseDetails?.coursetype === "paid" && (
                      <tr>
                        <td>Percentage Discount &#58;</td>
                        <td>
                          <p>{courseDetails?.perdis} %</p>
                        </td>
                      </tr>
                    )}
                  {courseDetails?.priceafterdisinr !== null &&
                    courseDetails?.priceafterdisusd !== null &&
                    courseDetails?.coursetype === "paid" && (
                      <tr>
                        <td>Price after Discount &#58;</td>
                        <td>
                          <p>
                            INR {+courseDetails?.priceafterdisinr} + GST /{" "}
                            <Box component="span" sx={{ mx: 0.3 }}>
                              {" "}
                              USD{" "}
                            </Box>
                            {+courseDetails?.priceafterdisusd}{" "}
                          </p>
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>

              <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                <StyledButton onClick={handleLoginClickOpen("login")}>
                  Start
                </StyledButton>
              </Stack>
            </Paper>
            <Paper sx={{ boxShadow: Theme.boxShadow }} className="item-3">
              <HeadingSecondary style={{ margin: "24px" }}>
                modules
              </HeadingSecondary>

              {courseDetails?.nuggets?.map((nugget, nidx) => (
                <Box key={nidx + "nmv"}>
                  <Stack
                    direction={{ sm: "row" }}
                    alignItems={{ sm: "center" }}
                    justifyContent={{ sm: "space-between" }}
                    spacing={1}
                    sx={{ mr: { xs: 0, sm: 1 } }}
                  >
                    <Box
                      key={nidx + "12ASDF"}
                      sx={{
                        background: `linear-gradient(to right, ${Theme.color.secondary} 55%, ${Theme.color.secondary} 100%)`,
                        width: "max-content",
                        maxWidth: 800,
                        pl: { xs: 1, lg: "5%" },
                        py: 1,
                        pr: 2,
                        borderRadius: "0 30px 30px 0",
                        color: "#fff",
                      }}
                    >
                      {`${nugget.ntitle}`}
                    </Box>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{ mr: { xs: 0, sm: 1 } }}
                    >
                      <AccessTime style={{ fontSize: "21px" }} />
                      <span>
                        {Math.floor(nugget.nduration / 3600) === 0
                          ? ""
                          : Math.floor(nugget.nduration / 3600) + "h"}
                        {Math.floor((nugget.nduration % 3600) / 60)}m
                        {/* {Math.floor((nugget.nduration % 3600) % 60)}s */}
                      </span>
                    </Stack>
                  </Stack>
                  {nugget?.objects?.map((object, oidx) => (
                    <Stack
                      key={oidx + "JKS"}
                      sx={{ height: "50px", pl: { xs: 1, lg: "2%" } }}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Box>
                        <RenderImage type={object.otype} oStatus={object.op} />
                      </Box>

                      <Box>{object.otitle}</Box>
                    </Stack>
                  ))}
                </Box>
              ))}
            </Paper>
          </Stack>
          {openSpeaker && (
            <SpeakerDialog
              open={openSpeaker}
              setOpen={setSpeaker}
              speakerDetails={speakerDetails}
            />
          )}
          {loginOpen && (
            <SignInAndSignUp
              loginOpen={loginOpen}
              setLoginOpen={setLoginOpen}
              onlySingUp={onlySignUp}
            />
          )}
        </Wrapper>
      </Stack>
    </>
  );
};

export default PreLoginCourse;
