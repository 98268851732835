import { useEffect } from "react";
import { Box } from "@mui/system";

import CardPrimary from "../../components/Card/Card.Primary";
import config from "../../config/aws-exports";

import { cardSecondarySettings, settings } from "../Home/SlickSettings";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
// import CardSecondary from "../../components/Card/Card.Secondary";
import { HeadingPrimary } from "../../components/Styles/Heading";
import { Link, useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
// import useFetch from "../../Hooks/useFetch";
import { useQuery } from "react-query";
import { API } from "aws-amplify";
import { session } from "../../redux/Session/Session";
import moment from "moment";
import { Stack } from "@mui/material";
import styled from "styled-components";
import { sessionAll } from "../../redux/viewAll/viewAll";
import CardSecondary from "../../components/Card/Card.Secondary";
import emptysearch from "../../assets/Nothing here yet.gif";

export const CusLink = styled(Link)`
  color: ${({ theme }) => theme.color.secondary};
`;

export const SkeletonData = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "2rem",
    }}
  >
    {[...Array(5)].map((item, id) => (
      <Box key={id}>
        <Skeleton variant="rectangular" width={310} height={218} />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </Box>
    ))}
  </Box>
);
export default function Dashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector(authData);

  //popular course
  const { data } = useQuery("popularSessionAndCourse", () =>
    API.post(config.aws_cloud_logic_custom_name, "/getWebSessionAndCourse", {
      body: {
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err))
  );

  const dashboardData = useQuery(
    [
      "dashboard",
      {
        tenant: userDetails?.uData?.oid,
        eid: userDetails?.sub,
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
      },
    ],
    async () => {
      try {
        // const id = await parseInt(window.localStorage.getItem("ur_id"));
        const res = await API.post(
          config.aws_cloud_logic_custom_name,
          "/dashboard",
          {
            body: {
              tenant: userDetails?.uData?.oid,
              eid: userDetails?.sub,
              ur_id: userDetails?.uData?.ur_id,
              schema: config.schema,
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: jwttoken,
            },
          }
        );
     
        const allELearnCourses = Array.from(
          new Set(res?.allCourse?.map((item) => item.tid))
        ).map((id) => {
          return res?.allCourse?.find((item) => item.tid === id);
        });

        res.allCourse = allELearnCourses;
        const continueLearning = res?.allCourse?.filter(
          (completed) =>
            completed.sub_date !== null && completed.comp_date === null
        );
        const completedCourse = res?.allCourse?.filter(
          (completed) =>
            completed.sub_date !== null && completed.comp_date !== null
        );
        res.completedCourse = completedCourse;
        res.continueLearning = continueLearning;
        return res;
      } catch (err) {
        console.error(err.message);
      }
    }
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (dashboardData.isError)
    return <h1>{JSON.stringify(dashboardData.error)}</h1>;

  const PopularSession = userDetails &&
    dashboardData.data?.popularSession?.length > 0 && (
      <Box sx={{ m: { xs: 1, sm: 2, md: 3 } }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <HeadingPrimary>Popular Session</HeadingPrimary>
          {dashboardData.data?.popularSession?.length > 10 && (
            <CusLink
              to="/viewall"
              onClick={() => {
                dispatch(
                  sessionAll({
                    type: "session",
                    header: "Popular Session",
                    data: dashboardData.data?.popularSession,
                  })
                );
                history.push("/viewall");
              }}
            >
              View all
            </CusLink>
          )}
        </Stack>
        <Box sx={{ mt: 2 }} />
        <Slider {...settings}>
          {dashboardData.data?.popularSession
            ?.slice(0, 10)
            ?.map((item, idx) => (
              <Stack direction="row" key={item?.sid + "UKddC"}>
                <CardPrimary
                  auth={true}
                  title={item.sn}
                  img={
                    item.sid &&
                    `https://${
                      config.aws_cloudfront_url
                    }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                      item.sid
                    }.png`
                  }
                  addToBookMarkList={() => {}}
                  addToFavorite={item.EI}
                  sessionType={item.stype}
                  sessionDate={
                    item.date_list
                      ? moment(
                          new Date(item.date_list[0].combineStartTime).getTime()
                        ).format("DD MMM YYYY")
                      : item.startdate
                      ? moment(parseInt(item.startdate)).format("DD MMM YYYY")
                      : "yet to be scheduled"
                  }
                  handleLocation={() => {
                    history.push(`/session?sid=${item.sid}`);
                    dispatch(session(item));
                  }}
                />
              </Stack>
            ))}
        </Slider>
      </Box>
    );
  const RegisteredSession = userDetails &&
    dashboardData.data?.registered?.length > 0 && (
      <Box sx={{ m: { xs: 1, sm: 2, md: 3 } }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <HeadingPrimary>Registered Sessions</HeadingPrimary>
          {dashboardData.data?.registered?.length > 10 && (
            <CusLink
              to="/viewall"
              onClick={() => {
                dispatch(
                  sessionAll({
                    type: "session",
                    header: "Registered Sessions",
                    data: dashboardData.data?.registered,
                  })
                );
                history.push("/viewall");
              }}
            >
              View all
            </CusLink>
          )}
        </Stack>
        <Box sx={{ mt: 2 }} />
        <Slider {...settings}>
          {dashboardData.data?.registered?.slice(0, 10)?.map((item, idx) => (
            <Stack direction="row" key={item?.sid + "UKddC"}>
              <CardPrimary
                auth={true}
                title={item.sn}
                img={
                  item.sid &&
                  `https://${
                    config.aws_cloudfront_url
                  }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                    item.sid
                  }.png`
                }
                addToBookMarkList={() => {}}
                addToFavorite={item.EI}
                sessionType={item.stype}
                sessionDate={
                  item.date_list
                    ? moment(
                        new Date(item.date_list[0].combineStartTime).getTime()
                      ).format("DD MMM YYYY")
                    : item.startdate
                    ? moment(parseInt(item.startdate)).format("DD MMM YYYY")
                    : "yet to be scheduled"
                }
                handleLocation={() => {
                  history.push(`/session?sid=${item.sid}`);
                  dispatch(session(item));
                }}
              />
            </Stack>
          ))}
        </Slider>
      </Box>
    );

  const AllCourse = dashboardData.data?.allCourse?.length > 0 && (
    <Box sx={{ m: { xs: 1, sm: 2, md: 3 } }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <HeadingPrimary>Self-paced courses</HeadingPrimary>
        {dashboardData.data?.allCourse?.length > 10 && (
          <CusLink
            to="/viewall"
            onClick={() => {
              dispatch(
                sessionAll({
                  type: "course",
                  header: "Self-paced courses",
                  data: dashboardData.data?.allCourse,
                })
              );
              history.push("/viewall");
            }}
          >
            View all
          </CusLink>
        )}
      </Stack>
      <Slider {...cardSecondarySettings}>
        {/* {[...Array(5)].map((item, idx) => ( */}
        {dashboardData.data?.allCourse?.slice(0, 10)?.map((item, idx) => (
          <CardSecondary
            key={idx}
            img={
              item?.tid &&
              `https://${
                config.aws_cloudfront_url
              }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
                item?.tid
              }.png`
            }
            title={
              item?.ttitle.length > 30
                ? item?.ttitle.substring(0, 30) + "..."
                : item?.ttitle
            }
            sessionDate={
              item.startdate &&
              moment(parseInt(item.startdate)).format("DD MMM YYYY")
            }
            handleLocation={() => {
              history.push(`/course?id=${item?.tid}`);
              dispatch(session({ ...item }));
            }}
            nonug={item?.noofnuggets}
          />
        ))}
        {/* ))} */}
      </Slider>
    </Box>
  );
  // const PopularCourse = dashboardData.data?.popularCourse?.length > 0 && (
  //   <Box sx={{ m: { xs: 1, sm: 2, md: 3 } }}>
  //     <Stack direction="row" justifyContent="space-between" alignItems="center">
  //       <HeadingPrimary>Popular Course</HeadingPrimary>
  //       {dashboardData?.data?.popularCourse?.length > 10 && (
  //         <CusLink
  //           to="/viewall"
  //           onClick={() => {
  //             dispatch(
  //               sessionAll({
  //                 type: "course",
  //                 header: "Popular Course",
  //                 data: dashboardData?.data?.popularCourse,
  //               })
  //             );
  //             history.push("/viewall");
  //           }}
  //         >
  //           View all
  //         </CusLink>
  //       )}
  //     </Stack>
  //     <Slider {...cardSecondarySettings}>
  //       {/* {[...Array(5)].map((item, idx) => ( */}
  //       {dashboardData?.data?.popularCourse?.slice(0, 10)?.map((item, idx) => (
  //         <CardSecondary
  //           key={idx}
  //           img={
  //             item?.tid &&
  //             `https://${
  //               config.aws_cloudfront_url
  //             }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
  //               item?.tid
  //             }.png`
  //           }
  //           title={item?.tn}
  //           sessionDate={
  //             item.startdate &&
  //             moment(parseInt(item.startdate)).format("DD MMM YYYY")
  //           }
  //           handleLocation={() => {
  //             history.push(`/course?id=${item?.tid}`);
  //             dispatch(session({ ...item }));
  //           }}
  //           nonug={item?.nugcnt}
  //         />
  //       ))}
  //       {/* ))} */}
  //     </Slider>
  //   </Box>
  // );
  // const PopularCourse = data?.course?.length > 0 && (
  //   <Box sx={{ m: { xs: 1, sm: 2, md: 3 } }}>
  //     <Stack direction="row" justifyContent="space-between" alignItems="center">
  //       <HeadingPrimary>Popular self-paced courses</HeadingPrimary>
  //       {data?.course?.length > 10 && (
  //         <CusLink
  //           to="/viewall"
  //           onClick={() => {
  //             dispatch(
  //               sessionAll({
  //                 type: "course",
  //                 header: "Popular self-paced courses",
  //                 data: data?.course,
  //               })
  //             );
  //             history.push("/viewall");
  //           }}
  //         >
  //           View all
  //         </CusLink>
  //       )}
  //     </Stack>
  //     <Slider {...cardSecondarySettings}>
  //       {/* {[...Array(5)].map((item, idx) => ( */}
  //       {data?.course?.slice(0, 10)?.map((item, idx) => (
  //         <CardSecondary
  //           key={idx}
  //           img={
  //             item?.tid &&
  //             `https://${
  //               config.aws_cloudfront_url
  //             }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
  //               item?.tid
  //             }.png`
  //           }
  //           title={item?.tn}
  //           sessionDate={
  //             item.startdate &&
  //             moment(parseInt(item.startdate)).format("DD MMM YYYY")
  //           }
  //           handleLocation={() => {
  //             history.push(`/course?id=${item?.tid}`);
  //             dispatch(session({ ...item }));
  //           }}
  //           nonug={item?.nugcnt}
  //         />
  //       ))}
  //       {/* ))} */}
  //     </Slider>
  //   </Box>
  // );
  const ContinueLearning = dashboardData.data?.continueLearning?.length > 0 && (
    <Box sx={{ m: { xs: 1, sm: 2, md: 3 } }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <HeadingPrimary>Continue Learning</HeadingPrimary>
        {dashboardData.data?.continueLearning?.length > 10 && (
          <CusLink
            to="/viewall"
            onClick={() => {
              dispatch(
                sessionAll({
                  type: "course",
                  header: "Continue Learning",
                  data: dashboardData.data?.continueLearning,
                })
              );
              history.push("/viewall");
            }}
          >
            View all
          </CusLink>
        )}
      </Stack>
      <Slider {...cardSecondarySettings}>
        {/* {[...Array(5)].map((item, idx) => ( */}
        {dashboardData.data?.continueLearning
          ?.slice(0, 10)
          ?.map((item, idx) => (
            <CardSecondary
              key={idx}
              img={
                item?.tid &&
                `https://${
                  config.aws_cloudfront_url
                }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
                  item?.tid
                }.png`
              }
              title={item?.ttitle}
              sessionDate={
                item.startdate &&
                moment(parseInt(item.startdate)).format("DD MMM YYYY")
              }
              handleLocation={() => {
                history.push(`/course?id=${item?.tid}`);
                dispatch(session({ ...item }));
              }}
              nonug={item?.noofnuggets}
            />
          ))}
        {/* ))} */}
      </Slider>
    </Box>
  );
  const CompletedCourse = dashboardData.data?.completedCourse?.length > 0 && (
    <Box sx={{ m: { xs: 1, sm: 2, md: 3 } }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <HeadingPrimary>Completed self-paced courses</HeadingPrimary>
        {dashboardData.data?.completedCourse?.length && (
          <CusLink
            to="/viewall"
            onClick={() => {
              dispatch(
                sessionAll({
                  type: "course",
                  header: "Completed self-paced course",
                  data: dashboardData.data?.completedCourse,
                })
              );
              history.push("/viewall");
            }}
          >
            View all
          </CusLink>
        )}
      </Stack>
      <Slider {...cardSecondarySettings}>
        {/* {[...Array(5)].map((item, idx) => ( */}
        {dashboardData.data?.completedCourse?.slice(0, 10)?.map((item, idx) => (
          <CardSecondary
            key={idx}
            img={
              item?.tid &&
              `https://${
                config.aws_cloudfront_url
              }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
                item?.tid
              }.png`
            }
            title={item?.ttitle}
            sessionDate={
              item.startdate &&
              moment(parseInt(item.startdate)).format("DD MMM YYYY")
            }
            handleLocation={() => {
              history.push(`/course?id=${item?.tid}`);
              dispatch(session({ ...item }));
            }}
            nonug={item?.noofnuggets}
          />
        ))}
        {/* ))} */}
      </Slider>
    </Box>
  );
  const UpcomingSession = userDetails &&
    dashboardData.data?.upcoming?.length > 0 && (
      <Box sx={{ m: { xs: 1, sm: 2, md: 3 }, mt: 100 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <HeadingPrimary>Upcoming Sessions</HeadingPrimary>
          {dashboardData.data?.upcoming?.length > 10 && (
            <CusLink
              to="/viewall"
              onClick={() => {
                dispatch(
                  sessionAll({
                    type: "session",
                    header: "Upcoming Sessions",
                    data: dashboardData?.data?.upcoming,
                  })
                );
                history.push("/viewall");
              }}
            >
              View all
            </CusLink>
          )}
        </Stack>
        <Box sx={{ mt: 2 }} />
        <Slider {...settings}>
          {dashboardData.data?.upcoming?.slice(0, 10)?.map((item, idx) => (
            <Stack direction="row" key={idx + "UKC"}>
              <CardPrimary
                auth={true}
                title={item.sn}
                img={
                  item.sid &&
                  `https://${
                    config.aws_cloudfront_url
                  }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                    item.sid
                  }.png`
                }
                addToBookMarkList={() => {}}
                addToFavorite={item.EI}
                sessionType={item.stype}
                sessionDate={
                  item.date_list
                    ? item.date_list[0]?.date === ""
                      ? "yet to be scheduled"
                      : moment(
                          new Date(item.date_list[0].combineStartTime).getTime()
                        ).format("DD MMM YYYY")
                    : item.startdate
                    ? moment(parseInt(item.startdate)).format("DD MMM YYYY")
                    : "yet to be scheduled"
                }
                handleLocation={() => {
                  history.push(`/session?sid=${item.sid}`);
                  dispatch(session(item));
                }}
              />
            </Stack>
          ))}
        </Slider>
      </Box>
    );
  const OnGoingSession = userDetails &&
    dashboardData.data?.ongoing?.length > 0 && (
      <Box sx={{ m: { xs: 1, sm: 2, md: 3 }, mt: 100 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <HeadingPrimary>Ongoing Sessions</HeadingPrimary>
          {dashboardData.data?.ongoing?.length > 10 && (
            <CusLink
              to="/viewall"
              onClick={() => {
                dispatch(
                  sessionAll({
                    type: "session",
                    header: "Upcoming Sessions",
                    data: dashboardData.data?.ongoing,
                  })
                );
                history.push("/viewall");
              }}
            >
              View all
            </CusLink>
          )}
        </Stack>
        <Box sx={{ mt: 2 }} />
        <Slider {...settings}>
          {dashboardData.data?.ongoing?.slice(0, 10)?.map((item, idx) => (
            <Stack direction="row" key={idx + "UKC"}>
              <CardPrimary
                auth={true}
                title={item.sn}
                img={
                  item.sid &&
                  `https://${
                    config.aws_cloudfront_url
                  }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                    item.sid
                  }.png`
                }
                addToBookMarkList={() => {}}
                addToFavorite={item.EI}
                sessionType={item.stype}
                sessionDate={
                  item.date_list
                    ? item.date_list[0]?.date === ""
                      ? "yet to be scheduled"
                      : moment(
                          new Date(item.date_list[0].combineStartTime).getTime()
                        ).format("DD MMM YYYY")
                    : item.startdate
                    ? moment(parseInt(item.startdate)).format("DD MMM YYYY")
                    : "yet to be scheduled"
                }
                handleLocation={() => {
                  history.push(`/session?sid=${item.sid}`);
                  dispatch(session(item));
                }}
              />
            </Stack>
          ))}
        </Slider>
      </Box>
    );
  const CompletedSession = dashboardData.data?.completed?.length > 0 && (
    <Box sx={{ m: { xs: 1, sm: 2, md: 3 } }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <HeadingPrimary>Completed Sessions</HeadingPrimary>
        {dashboardData.data?.completed?.length > 10 && (
          <CusLink
            to="/viewall"
            onClick={() => {
              dispatch(
                sessionAll({
                  type: "session",
                  header: "Completed Sessions",
                  data: dashboardData?.data?.completed,
                })
              );
              history.push("/viewall");
            }}
          >
            View all
          </CusLink>
        )}
      </Stack>
      <Box sx={{ mt: 2 }} />
      <Slider {...settings}>
        {dashboardData?.data?.completed.slice(0, 10).map((item, idx) => (
          <Stack direction="row" key={idx + "DCS"}>
            <CardPrimary
              auth={true}
              title={item.sn}
              img={
                item.sid &&
                `https://${
                  config.aws_cloudfront_url
                }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                  item.sid
                }.png`
              }
              addToBookMarkList={() => {}}
              addToFavorite={item.EI}
              sessionType={item.stype}
              sessionDate={
                item.date_list
                  ? moment(
                      new Date(item.date_list[0].combineStartTime).getTime()
                    ).format("DD MMM YYYY")
                  : item.startdate
                  ? moment(parseInt(item.startdate)).format("DD MMM YYYY")
                  : "yet to be scheduled"
              }
              handleLocation={() => {
                history.push(`/session?sid=${item.sid}`);
                dispatch(session(item));
              }}
            />
          </Stack>
        ))}
      </Slider>
    </Box>
  );

  return (
    <>
      {dashboardData.isLoading && <SkeletonData />}
      {!dashboardData.isLoading && PopularSession}
      {!dashboardData.isLoading && ContinueLearning}
      {!dashboardData.isLoading && RegisteredSession}
      {!dashboardData.isLoading && AllCourse}
      {/* {!dashboardData.isLoading && PopularCourse} */}
      {!dashboardData.isLoading && UpcomingSession}
      {!dashboardData.isLoading && OnGoingSession}
      {!dashboardData.isLoading && CompletedSession}
      {!dashboardData.isLoading && CompletedCourse}

      {dashboardData.data?.allCourse?.length === 0 &&
        dashboardData.data?.completedCourse?.length === 0 &&
        data?.course?.length === 0 &&
        dashboardData.data?.myTopics?.length === 0 &&
        dashboardData.data?.continueLearning?.length === 0 &&
        dashboardData.data?.upcoming?.length === 0 &&
        dashboardData.data?.completed?.length === 0 &&
        dashboardData.data?.popularSession?.length === 0 &&
        dashboardData.data?.ongoing?.length === 0 &&
        dashboardData.data?.registered?.length === 0 && (
          <div
            style={{
              height: "60vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <img src={emptysearch} alt="emptysearch" />
          </div>
        )}
    </>
  );
}
