//components
import AppBarLandingPage from "../../components/AppBar/AppBar.landingPage";
import CardPrimary from "../../components/Card/Card.Primary";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import ArrowBackIosTwoToneIcon from "@mui/icons-material/ArrowBackIosTwoTone";
import Slider from "react-slick";

import { Container, IconButton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";
import config from "../../config/aws-exports";

// import useFetch from "../../Hooks/useFetch";
//slick settings
import { cardSecondarySettings, cardSettings, settings } from "./SlickSettings";

import SignInAndSignUp from "../../components/SignInAndSignUp/SignInAndSignUp";
import { useRef, useState, useEffect } from "react";
import CardSecondary from "../../components/Card/Card.Secondary";
import { HeadingPrimary } from "../../components/Styles/Heading";
import { CustomButton } from "../../components/CustomButton/CustomButton";
// react router dom
import { useHistory } from "react-router-dom";

import { useQuery, useQueryClient } from "react-query";
// redux
import { useDispatch } from "react-redux";
import { API } from "aws-amplify";
import { session } from "../../redux/Session/Session";
import moment from "moment";
// import slide1 from "./slide1.jpg";
// import slide2 from "./slide2.jpg";
// import slide3 from "./slide3.jpg";
const slides = [
  {
    name: "slide1",
    image: `https://${
      config.aws_cloudfront_url
    }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/slide1.jpg`,
  },
  {
    name: "slide2",
    image: `https://${
      config.aws_cloudfront_url
    }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/slide2.jpg`,
  },
  {
    name: "slide3",
    image: `https://${
      config.aws_cloudfront_url
    }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/slide3.jpg`,
  },
];
export function SampleNextArrow(props) {
  console.log(props);
  const { className, style, onClick } = props;

  return (
    <IconButton
      onClick={onClick}
      // className={className}
      sx={{
        position: "absolute",
        right: "-35px",
        top: "50%",
        transform: "translateY(-30px)",
        zIndex: "800",
        display: { xs: "none", md: "block" },
      }}
    >
      <ArrowForwardIosTwoToneIcon
        // style={{ fontSize: "2.5rem", color: "red" }}
        style={{
          // ...style,
          display: "block",
          // background: "red",
          fontSize: "2.5rem",
        }}
      />
    </IconButton>
    // <div
    //   className={className}
    //   style={{ ...style, display: "block", background: "red" }}
    //   onClick={onClick}
    // />
  );
}

export function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <IconButton
      onClick={onClick}
      // className={className}
      sx={{
        position: "absolute",
        left: "-40px",
        top: "50%",
        transform: "translateY(-30px)",
        zIndex: "800",
        display: { xs: "none", md: "block" },
      }}
    >
      <ArrowBackIosTwoToneIcon
        style={{
          // ...style,
          display: "block",
          // background: "red",
          fontSize: "2.5rem",
        }}
      />
    </IconButton>
    // <div
    //   className={className}
    //   style={{ ...style, display: "block", background: "red" }}
    //   onClick={onClick}
    // />
  );
}
const settingsCarousel = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

export default function Home() {
  const dispatch = useDispatch();
  const [loginOpen, setLoginOpen] = useState(false);
  const onlySignUp = useRef(false);

  const history = useHistory();

  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const { data, isLoading } = useQuery("popularSessionAndCourse", () =>
    API.post(config.aws_cloud_logic_custom_name, "/getWebSessionAndCourse", {
      body: {
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err))
  );

  const handleLoginClickOpen = (prop) => () => {
    if (prop === "login") {
      setLoginOpen(true);
      onlySignUp.current = false;
    } else {
      setLoginOpen(true);
      onlySignUp.current = true;
    }
  };
  // const settings = {
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  // };
  const popularSession = data?.session?.length > 0 && (
    <Box sx={{ m: { xs: "1rem", sm: "3rem" } }}>
      <HeadingPrimary>Popular Sessions</HeadingPrimary>
      <Slider {...settings}>
        {data?.session?.map((item, idx) => (
          <Stack>
            <CardPrimary
              key={idx}
              img={
                item.sid &&
                `https://${
                  config.aws_cloudfront_url
                }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                  item.sid
                }.png`
              }
              auth={false}
              title={item.sn}
              sessionDate={
                item.date_list
                  ? moment(new Date(item.date_list[0].combineStartTime)).format(
                      "DD MMM YYYY"
                    )
                  : item.startdate
                  ? moment(parseInt(item.startdate)).format("DD MMM YYYY")
                  : "yet to be scheduled"
              }
              handleLocation={() => {
                history.push("/prelogin");
                dispatch(session({ ...item, preLogin: true }));
              }}
              addToBookMarkList={() => {}}
              addToFavorite={() => {}}
            />
          </Stack>
        ))}
      </Slider>
    </Box>
  );
  const popularCourse = data?.course?.length > 0 && (
    <Box sx={{ m: { xs: "1rem", sm: "3rem" } }}>
      <HeadingPrimary>Popular self-paced courses</HeadingPrimary>
      <Slider {...cardSecondarySettings}>
        {/* {[...Array(5)].map((item, idx) => ( */}
        {data?.course?.map((item, idx) => (
          <CardSecondary
            key={idx}
            img={
              item?.tid &&
              `https://${
                config.aws_cloudfront_url
              }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
                item?.tid
              }.png`
            }
            title={item?.tn}
            sessionDate={moment(parseInt(item?.startdate)).format(
              "DD MMM YYYY"
            )}
            handleLocation={() => {
              history.push(`/preCourse?id=${item?.tid}`);
              dispatch(session({ ...item }));
            }}
            nonug={item?.nugcnt}
          />
        ))}
        {/* ))} */}
      </Slider>
    </Box>
  );

  return (
    <Box sx={{ pb: 1 }}>
      <AppBarLandingPage handleLoginClick={handleLoginClickOpen} />
      <Slider {...settingsCarousel}>
        {slides.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              backgroundImage: `url('${item.image}')`,
              height: "75vh",
              width: "100vw",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              display: "grid",
              placeItems: "top",
            }}
          >
            <Stack
              direction="column"
              spacing={8}
              justifyContent="center"
              style={{ minHeight: "100%" }}
            >
              <h1
                style={{
                  fontSize: "4vw",
                  color: "#fff",
                  fontWeight: "900",
                  letterSpacing: "1.3px",
                  textAlign: "center",
                }}
              >
                Empowering a healthy tomorrow
              </h1>
              <Stack direction="row" spacing={5} justifyContent="center">
                <CustomButton onClick={handleLoginClickOpen("login")} primary>
                  Login
                </CustomButton>
                <CustomButton onClick={handleLoginClickOpen("signUp")} primary>
                  Sign up
                </CustomButton>
              </Stack>
            </Stack>
          </Box>
        ))}
      </Slider>

      {!isLoading && popularSession}
      {!isLoading && popularCourse}

      {loginOpen && (
        <SignInAndSignUp
          loginOpen={loginOpen}
          setLoginOpen={setLoginOpen}
          onlySingUp={onlySignUp}
        />
      )}
    </Box>
  );
}
